import { APIResponse, PredictionRun } from "@customTypes/data";
import { useNavigate } from "@tanstack/react-location";
import dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import { useInfiniteQuery } from "react-query";
import { djangoBackend } from "services/apiServices";
import { predictionRunsUrl } from "../constants";
import AppLayout from "./Layouts/AppLayout";
import React from "react";
dayjs.extend(relativeTime.default);

const QUERY_IDENTIFIER = "predictionruns";
const PAGE_SIZE = 20;

export default function PredictionRunList() {
  return (
    <AppLayout requiresAuthentication={true}>
      <PredictionRunListInternal />
    </AppLayout>
  );
}
function PredictionRunListInternal() {
  const navigate = useNavigate();

  const { isLoading, hasNextPage, data, fetchNextPage, error, refetch } =
    useInfiniteQuery<APIResponse<PredictionRun>>(
      [QUERY_IDENTIFIER],
      ({ pageParam = 1 }) => {
        const url = `${predictionRunsUrl}?page_size=${PAGE_SIZE}&page=${pageParam}`;
        return djangoBackend.get(url).then((res) => {
          return res.data;
        });
      },
      {
        getNextPageParam: (lastPage, pages) => {
          return lastPage.next ? pages.length + 1 : undefined;
        },
      }
    );

  const datasets = data?.pages.flatMap(
    (datasetResponse: APIResponse<PredictionRun>) => {
      console.log("datasetResponse: ", datasetResponse);
      return datasetResponse.results;
    }
  );
  if (datasets) {
    console.log("datasets:", datasets);
  }

  if (isLoading) return <div>Loading...</div>;

  if (error && error instanceof Error)
    return <div>An error has occurred: {error.message}</div>;

  if (!data) {
    return <div>Not loaded yet</div>;
  } else {
    return (
      <div className="px-4 mt-2 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Prediction Runs
            </h1>
          </div>
        </div>
        <div className="mt-4 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Action
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Prediction Run Id
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Dataset
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Author
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Creation Date
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {datasets?.map(
                      (predictionRun: PredictionRun, datasetIdx) => {
                        const createdAt = dayjs(predictionRun.createdAt);
                        return (
                          <tr
                            key={predictionRun.id}
                            className={
                              datasetIdx % 2 === 0 ? undefined : "bg-gray-50"
                            }
                          >
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                              <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                <button
                                  onClick={() =>
                                    navigate({
                                      to: "/compare",
                                      search: {
                                        predictionRuns: [predictionRun.id],
                                        dataset: predictionRun.dataset,
                                      },
                                    })
                                  }
                                  type="button"
                                  className={`relative inline-flex items-center px-4 py-2 rounded border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
                                >
                                  Compare
                                </button>
                              </span>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {predictionRun.id}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {predictionRun.dataset}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {predictionRun.author.email}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {createdAt.fromNow()} (
                              {createdAt.format("YYYY-MM-DD HH:mm:ss")})
                            </td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
              </div>
              <div className="text-center mt-5">
                {hasNextPage && (
                  <button
                    onClick={() => fetchNextPage()}
                    type="button"
                    className="content-center	 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Load More
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
