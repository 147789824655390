import { APIResponse, Dataset, Prediction } from "@customTypes/data";
import { useInfiniteQuery, useQuery } from "react-query";
import { djangoBackend } from "services/apiServices";
import { predictionsUrl, PREDICTION_QUERY_IDENTIFIER } from "../constants";
import zip from "lodash/zip";

const PREDICTIONS_PAGE_SIZE = 500;

async function fetchAllPages(predictionRun: string): Promise<Prediction[]> {
  let allPredictions: Prediction[] = [];
  const morePagesAvailable = true;
  let currentPage = 1;

  while (morePagesAvailable) {
    const url = `${predictionsUrl}?predictionRun=${predictionRun}&page=${currentPage}&page_size=${PREDICTIONS_PAGE_SIZE}`;

    const { data } = await djangoBackend.get(url);
    allPredictions = allPredictions.concat(data.results);

    console.log("fetching page:", currentPage);
    if (!data.next) {
      break;
    }
    currentPage++;
  }

  return allPredictions;
}

export const usePredictions = ({
  predictionRuns,
}: {
  predictionRuns: string[];
}) => {
  const predictionRequestData = useQuery<Map<string, Prediction>[]>(
    [PREDICTION_QUERY_IDENTIFIER].concat(predictionRuns),
    async () => {
      const allRuns = await Promise.all(
        predictionRuns.map(async (predictionRun) => {
          return fetchAllPages(predictionRun);
        })
      );

      // Very bad code, apologies (from Eliot) if you have to debug this
      // Our goal: access all prediction runs we've asked for
      // Unfortunately some might be incomplete (missing images with regards to the dataset)
      // So flatten, then return a list of dict {predictionRun: Prediction}
      // Ready to be displayed by the table
      // source: data[predictionRun][0] = {imageId, etcc}
      // target: data[imageId][predictionRun]

      const flattennedPredictions = allRuns.flat() as Prediction[];
      const predictionsByImageId = new Map<string, Map<string, Prediction>>();

      flattennedPredictions.forEach((prediction) => {
        const imageId = prediction.image.id;
        const predictionRun = prediction.predictionRun;

        const predictionRunToPrediction =
          predictionsByImageId.get(imageId) || new Map<string, Prediction>();
        predictionRunToPrediction.set(predictionRun, prediction);
        predictionsByImageId.set(imageId, predictionRunToPrediction);
      });

      return Array.from(predictionsByImageId.values());
    },
    {
      staleTime: 60 * 60,
    }
  );

  return {
    ...predictionRequestData,
  };
};
