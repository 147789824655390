import { TrainingImage } from "@customTypes/data";
import {
  Masonry,
  MasonryScroller,
  useContainerPosition,
  useInfiniteLoader,
  usePositioner,
  useResizeObserver,
} from "masonic";
import React, { useCallback } from "react";
import { useWindowSize } from "@react-hook/window-size";
import {
  ListOnItemsRenderedProps,
  FixedSizeList,
  VariableSizeList,
} from "react-window";
import { stringify } from "querystring";
import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeGrid as Grid } from "react-window";

const NUM_COLUMNS = 6;

export default function NewImagesGrid({
  fetchMoreItems,
  onItemClicked,
  trainingImages,
  masonryUniqueKey,
}: {
  fetchMoreItems: (startIndex: number) => void;
  onItemClicked: (itemId: number) => void;
  trainingImages: TrainingImage[];
  masonryUniqueKey: string;
}) {
  const maybeLoadMore = useInfiniteLoader(fetchMoreItems, {
    isItemLoaded: (index, items) => !!items[index],
  });

  const CardWithClick = useCallback(
    (props) => (
      <MasonryCard
        {...props}
        handleClick={(data, index) => {
          onItemClicked(index);
        }}
      />
    ),
    [onItemClicked]
  );

  return (
    <>
      <Masonry
        items={trainingImages}
        key={masonryUniqueKey}
        columnGutter={8}
        columnWidth={172}
        overscanBy={3}
        onRender={maybeLoadMore}
        render={CardWithClick}
      />
    </>
  );
}

const MasonryCard = ({
  index,
  data,
  handleClick,
}: {
  index: number;
  data: TrainingImage;
  handleClick: (data: TrainingImage, index: number) => void;
}) => {
  const size = 200;
  const training_image_url = data.imageUrl200x200;

  return (
    <div style={{ width: size, height: size }}>
      <button onClick={() => handleClick(data, index)}>
        <img
          src={training_image_url}
          alt={training_image_url}
          className="object-cover"
          style={{ width: size, height: size }}
        ></img>
      </button>
    </div>
  );
};
