import { ReactNode } from "react";
import { Store } from "react-notifications-component";

export const addNotification = (
  message: string | ReactNode,
  type?: "success" | "danger" | "info" | "default" | "warning",
  duration = 5000
): void => {
  Store.addNotification({
    message,
    type: type || "info",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    dismiss: {
      duration,
    },
  });
};
