import constate from "constate";
import type { User } from "firebase/auth";
import { useEffect, useState } from "react";

import { onAuthStateChanged } from "services/firebaseService";

function useFirebaseUserInternal(): [User | null, boolean] {
  const [loading, setLoading] = useState(true);
  const [firebaseUser, setFirebaseUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged((user) => {
      setFirebaseUser(user);
      setLoading(false);
    });

    return () => {
      unsubscribe();
      setLoading(false);
    };
  }, []);

  return [firebaseUser, loading];
}

export const [UserProvider, useFirebaseUser] = constate(
  useFirebaseUserInternal
);
