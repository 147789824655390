import { TrainingImage } from "@customTypes/data";
import { useWindowSize } from "@react-hook/window-size";
import React, { useCallback } from "react";
import { FixedSizeGrid as Grid } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";
import AutoSizer from "react-virtualized-auto-sizer";

const NUM_COLUMNS = 6;

export function ReactWindowGrid({
  // Are there more items to load?
  // (This information comes from the most recent API request.)
  hasNextPage,
  isNextPageLoading,
  items,
  onItemClicked,
  // Callback function responsible for loading the next page of items.
  loadNextPage,
}: {
  loadNextPage: (startIndex: number, stopIndex: number) => Promise<void> | void;
  onItemClicked: (index: number) => Promise<void> | void;
  items: TrainingImage[];
  isNextPageLoading: boolean;
  hasNextPage: boolean;
}) {
  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const loadMoreItems = loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = (index: number) => !hasNextPage || index < items.length;

  const CardWithClick = useCallback(
    (props: {
      columnIndex: number;
      rowIndex: number;
      style: React.CSSProperties;
    }) => (
      <ReactWindowCard
        {...props}
        data={items[props.columnIndex + props.rowIndex * NUM_COLUMNS]}
        handleClick={(data, index) => {
          onItemClicked(index);
        }}
      />
    ),
    [onItemClicked]
  );

  const [windowWidth, windowHeight] = useWindowSize();

  return (
    <AutoSizer>
      {({ width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          itemCount={itemCount}
          threshold={40}
          loadMoreItems={loadMoreItems}
        >
          {({ onItemsRendered, ref }) => (
            <Grid
              className="List"
              columnCount={NUM_COLUMNS}
              columnWidth={width / NUM_COLUMNS}
              height={windowHeight - 100} // Magic number for header
              width={width}
              rowCount={itemCount / NUM_COLUMNS}
              rowHeight={200}
              ref={ref}
              onItemsRendered={(gridData) => {
                const {
                  visibleRowStartIndex,
                  visibleRowStopIndex,
                  visibleColumnStopIndex,
                  overscanRowStartIndex,
                  overscanRowStopIndex,
                  overscanColumnStopIndex,
                } = gridData;

                const overscanStartIndex =
                  overscanRowStartIndex * (overscanColumnStopIndex + 1);
                const overscanStopIndex =
                  overscanRowStopIndex * (overscanColumnStopIndex + 1);
                const visibleStartIndex =
                  visibleRowStartIndex * (visibleColumnStopIndex + 1);
                const visibleStopIndex =
                  visibleRowStopIndex * (visibleColumnStopIndex + 1);

                onItemsRendered({
                  overscanStartIndex,
                  overscanStopIndex,
                  visibleStartIndex,
                  visibleStopIndex,
                });
              }}
            >
              {CardWithClick}
            </Grid>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
}

const ReactWindowCard = ({
  rowIndex,
  columnIndex,
  data,
  handleClick,
  style,
}: {
  rowIndex: number;
  columnIndex: number;
  style: React.CSSProperties;
  data: TrainingImage;
  handleClick: (data: TrainingImage, index: number) => void;
}) => {
  const size = 200;

  return (
    <div style={style}>
      <button
        onClick={() => handleClick(data, rowIndex * NUM_COLUMNS + columnIndex)}
      >
        {!data ? (
          <div></div>
        ) : (
          <img
            src={data.imageUrl200x200}
            alt={data.imageUrl200x200}
            className="object-cover"
            decoding="async"
            style={{ width: size, height: size }}
          ></img>
        )}
      </button>
    </div>
  );
};
