import InfiniteLoader from "react-window-infinite-loader";
import { FixedSizeList as List } from "react-window";
import React, { useEffect } from "react";
import { useElementSize } from "usehooks-ts";

export const KImageSlidebarHeight = 150;

interface RowProps {
  index: number;
  style: React.CSSProperties;
}

interface Props {
  isItemLoaded: (index: number) => boolean;
  totalCount: number;
  loadMoreItems: (startIndex: number, stopIndex: number) => void;
  elementRef: any;
  row: ({ index, style }: RowProps) => JSX.Element;
  onPreviousItemSelected?: () => void;
  onNextItemSelected?: () => void;
}

export default function ImagesSlidebar({
  isItemLoaded,
  totalCount,
  loadMoreItems,
  elementRef,
  row,
  onPreviousItemSelected,
  onNextItemSelected,
}: Props) {
  const [imageSlidebarWrapper, elementSize] = useElementSize();

  // Add event listeners
  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (!onNextItemSelected || !onPreviousItemSelected) {
        return;
      }

      if (event.code === "ArrowRight") {
        onNextItemSelected();
      } else if (event.code === "ArrowLeft") {
        onPreviousItemSelected();
      }
    }
    window.addEventListener("keydown", onKeyDown!);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener("keydown", onKeyDown!);
    };
  }, [onPreviousItemSelected, onNextItemSelected]); // Empty array ensures that effect is only run on mount and unmount

  return (
    <div ref={imageSlidebarWrapper}>
      <InfiniteLoader
        isItemLoaded={isItemLoaded}
        itemCount={totalCount}
        loadMoreItems={loadMoreItems}
        ref={elementRef}
      >
        {({ onItemsRendered, ref }) => (
          <List
            className="List"
            height={KImageSlidebarHeight}
            itemCount={totalCount}
            itemSize={KImageSlidebarHeight}
            layout="horizontal"
            onItemsRendered={onItemsRendered}
            overscanCount={5}
            ref={ref}
            width={elementSize.width}
          >
            {row}
          </List>
        )}
      </InfiniteLoader>
    </div>
  );
}
