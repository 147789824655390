import React, { Dispatch, SetStateAction } from "react";
import { XIcon } from "@heroicons/react/outline";
import { TrainingImage } from "@customTypes/data";
import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime.default);

interface imageSidebarProps {
  setSidebarOpen: Dispatch<SetStateAction<boolean>>;
  trainingImage: TrainingImage;
}

export default function ImageSidebar({
  setSidebarOpen,
  trainingImage,
}: imageSidebarProps) {
  const details = [
    ["author", trainingImage.author.email],
    [
      "created at",
      dayjs.default(trainingImage.createdAt).format("YYYY-MM-DD HH:mm:ss"),
    ],
    ["width", trainingImage.width],
    ["height", trainingImage.height],
    ["resolution", trainingImage.resolution],
    ["url", trainingImage.imageUrlFullSize],
  ];
  return (
    <aside className="hidden w-96 bg-white p-8   border-l border-gray-200 overflow-y-auto lg:block h-full">
      <button className="float-right" onClick={() => setSidebarOpen(false)}>
        <XIcon className="h-6 w-6 text-gray-600 " />
      </button>
      <div className="pb-16 space-y-6">
        <div>
          <div className="mt-4 flex items-start justify-between">
            <div>
              <h2 className="text-lg font-medium text-gray-900">
                Image Information
              </h2>
              <p className="text-sm font-medium text-gray-500 break-words">
                {trainingImage.id}
              </p>
            </div>
          </div>
        </div>
        <div>
          <h3 className="font-medium text-gray-900">Information</h3>
          <dl className="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
            {details.map((detail) => (
              <div
                key={detail[0]}
                className="py-3 flex justify-between text-sm font-medium overflow-hidden"
              >
                <dt className="text-gray-500">{detail[0]}</dt>
                <dd className="text-gray-900 text-ellipsis width-80 ml-3">
                  {detail[1]}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </aside>
  );
}
