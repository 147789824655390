import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";
import type { User } from "firebase/auth";

import { getAuth,
  onAuthStateChanged as firebaseOnAuthStateChanged,
  Unsubscribe, } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyCvbxyG7zuLhkjliBHu0xZe63U0_PVEIPg",

  authDomain: "dataroom-74fd6.firebaseapp.com",

  projectId: "dataroom-74fd6",

  storageBucket: "dataroom-74fd6.appspot.com",

  messagingSenderId: "43567709619",

  appId: "1:43567709619:web:47b7b1d51ca84742165fc6",

  measurementId: "G-P6J75Z36BE"

};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);


export function onAuthStateChanged(
  callback: (user: User | null) => void
): Unsubscribe {
  return firebaseOnAuthStateChanged(getAuth(), callback);
}

export async function getIdToken(): Promise<string | undefined> {
  const idToken = await getAuth().currentUser?.getIdToken();
  return idToken;
}