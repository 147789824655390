import { APIResponse, Tag } from "@customTypes/data";
import { tagsUrl } from "../constants";
import { TAGS_QUERY_IDENTIFIER } from "../constants";
import { useInfiniteQuery, useQuery } from "react-query";
import { djangoBackend } from "services/apiServices";

export const useTags = () =>
  useInfiniteQuery<APIResponse<Tag>>(
    [TAGS_QUERY_IDENTIFIER],
    ({ pageParam = 1 }) => {
      // TODO: add proper fetching of all the pages
      const url = `${tagsUrl}?page_size=${5000}&page=${pageParam}`;
      return djangoBackend.get(url).then((res) => {
        return res.data;
      });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next ? pages.length + 1 : undefined;
      },
    }
  );
