import {
  Navigate,
  Outlet,
  ReactLocation,
  Router,
} from "@tanstack/react-location";
import React from "react";

import Login from "containers/Login/Login";
import NotFound from "containers/NotFound";
import { UserProfile } from "containers/UserProfile";
import DatasetList from "containers/DatasetList";
import DatasetDetails from "containers/DatasetDetails";
import PredictionRunList from "containers/PredictionRunList";
import ImageTagFilterUI from "containers/ImageTagFilterUI";
import TagList from "containers/TagList";
import CompareContainer from "containers/CompareContainer";

const location = new ReactLocation();

export default function AppRouter() {
  return (
    <Router
      location={location}
      routes={[
        {
          path: "/",
          element: <Navigate to="/dataset" replace fromCurrent />,
        },
        {
          path: "/login",
          children: [{ path: "/", element: <Login /> }],
        },
        {
          path: "/profile",
          children: [{ path: "/", element: <UserProfile /> }],
        },
        {
          path: "/compare",
          children: [
            {
              path: "/",
              element: <CompareContainer />,
            },
          ],
        },

        {
          path: "/explore",
          children: [{ path: "/", element: <ImageTagFilterUI /> }],
        },
        {
          path: "/dataset",
          children: [
            { path: "/", element: <DatasetList /> },
            { path: "/:datasetId", element: <DatasetDetails /> },
          ],
        },
        {
          path: "/tags",
          element: <TagList />,
        },
        {
          path: "/predictions",
          children: [{ path: "/", element: <PredictionRunList /> }],
        },

        { path: "*", element: <NotFound /> },
      ]}
    >
      <Outlet />
    </Router>
  );
}
