import { APIResponse, Tag, TrainingImage } from "@customTypes/data";
import { MakeGenerics, useNavigate, useSearch } from "@tanstack/react-location";
import { ReactWindowGrid } from "containers/ReactWindowImageGrid";
import React, { useCallback, useRef } from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import {
  ActionMeta,
  OnChangeValue,
  StylesConfig,
  createFilter,
} from "react-select";
import CreatableSelect from "react-select/creatable";
import {
  buildImageQueryUrl,
  djangoBackend,
  queryClient,
} from "services/apiServices";
import { EXPLORE_PAGE_SIZE, tagsUrl } from "../constants";
import AppLayout from "./Layouts/AppLayout";

import { Fragment } from "react";
import { Disclosure } from "@headlessui/react";
import { MinusSmIcon, PlusSmIcon } from "@heroicons/react/solid";
import { useTags } from "hooks/useTags";

const filters = [
  {
    id: "color",
    name: "TODO Filters",
    options: [
      { value: "white", label: "Not", checked: false },
      { value: "beige", label: "Implemented", checked: false },
      { value: "blue", label: "Yet", checked: true },
    ],
  },
];

interface SelectOption {
  readonly value: string;
  readonly label: string;
}

type MyLocationGenerics = MakeGenerics<{
  Search: {
    tags?: string[];
  };
}>;

function TagPicker({
  onTagChange,
  initialTags,
}: {
  onTagChange: (tags: string[]) => void;
  initialTags: string[];
}): JSX.Element {
  const initialNonVisionTags = initialTags.filter(
    (tag) => !tag.startsWith("vision_")
  );
  const initialVisionTags = initialTags.filter((tag) =>
    tag.startsWith("vision_")
  );
  const selectedNonVisionTags = useRef<string[]>(initialNonVisionTags);
  const selectedVisionTags = useRef<string[]>(initialVisionTags);

  const { isLoading: tagLoading, error: tagError, data: tagPages } = useTags();
  const tagData = tagPages?.pages.flatMap((page) => {
    return page.results.map((tag) => tag.id);
  });
  const handleChangeVisionTag = (
    newValue: OnChangeValue<SelectOption, true>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    console.log(`action: ${actionMeta.action}`);
    selectedVisionTags.current = newValue.map((tagOption) => tagOption.value);
    onTagChange(
      selectedVisionTags.current.concat(selectedNonVisionTags.current)
    );
  };

  const handleChangeNonVisionTag = (
    newValue: OnChangeValue<SelectOption, true>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    console.log(`action: ${actionMeta.action}`);
    selectedNonVisionTags.current = newValue.map(
      (tagOption) => tagOption.value
    );
    onTagChange(
      selectedVisionTags.current.concat(selectedNonVisionTags.current)
    );
  };

  if (tagError) {
    return <div>Error: {tagError}</div>;
  }

  const tagOptions = tagData?.map((tag) => {
    return { value: tag, label: tag };
  });

  const initialVisionOptions = initialVisionTags?.map((tag) => {
    return { value: tag, label: tag } as SelectOption;
  });

  const initialNonVisionOptions = initialNonVisionTags?.map((tag) => {
    return { value: tag, label: tag } as SelectOption;
  });
  type IsMulti = true;
  const customStyles: StylesConfig<SelectOption, IsMulti> = {
    multiValueLabel: (provided) => {
      return {
        ...provided,
        ...{ overflowX: "scroll", textOverflow: "initial" },
      };
    },
  };

  return (
    <div className="">
      <span>Vision Tags</span>
      <CreatableSelect
        filterOption={createFilter({ ignoreAccents: false })} // Speed optimisation
        isMulti
        onChange={handleChangeVisionTag}
        options={tagOptions?.filter((tag) => tag.label.startsWith("vision_"))}
        defaultValue={initialVisionOptions}
        isLoading={tagLoading}
      />
      <span>Tags</span>
      <CreatableSelect
        isMulti
        onChange={handleChangeNonVisionTag}
        options={tagOptions?.filter((tag) => !tag.label.startsWith("vision_"))}
        defaultValue={initialNonVisionOptions}
        isLoading={tagLoading}
        styles={customStyles}
      />
    </div>
  );
}

const EXPLORE_QUERY_IDENTIFIER = "explore";

export default function ImageTagFilterUI() {
  return (
    <AppLayout requiresAuthentication={true}>
      <ExploreUIInternal />
    </AppLayout>
  );
}

function ExploreUIInternal() {
  const navigate = useNavigate();
  const search = useSearch<MyLocationGenerics>();
  const initialTags = search?.tags || [];

  const selectedTags = useRef<string[]>(initialTags);

  const {
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    data,
    fetchNextPage,
    error,
  } = useInfiniteQuery<APIResponse<TrainingImage>>(
    [EXPLORE_QUERY_IDENTIFIER],
    ({ pageParam = 1 }) => {
      console.log({ selectedTags: selectedTags });
      const url = buildImageQueryUrl(selectedTags.current, pageParam);
      return djangoBackend.get(url).then((res) => {
        return res.data;
      });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next ? pages.length + 1 : undefined;
      },
    }
  );

  const onTagChange = (tags: string[]) => {
    selectedTags.current = tags;
    // TODO: use multiple clients IDs
    queryClient.invalidateQueries([EXPLORE_QUERY_IDENTIFIER]);

    navigate({
      search: (old) => ({
        ...old,
        tags: tags,
      }),
    });
  };
  const trainingImages = data?.pages.flatMap(
    (datasetResponse: APIResponse<TrainingImage>) => {
      return datasetResponse.results;
    }
  );

  const fetchMoreItems = useCallback(
    async (pageParam: number | null) => {
      if (hasNextPage && !isFetchingNextPage) {
        await fetchNextPage({ cancelRefetch: false, pageParam: pageParam });
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  const loadMoreItemsImageGrid = useCallback(
    (startIndex: number) => {
      const pageToLoad = Math.floor(startIndex / EXPLORE_PAGE_SIZE) + 1;
      fetchMoreItems(pageToLoad);
    },
    [fetchMoreItems]
  );

  if (isLoading) return <div>Loading...</div>;

  if (error && error instanceof Error)
    return <div>An error has occurred: {error.message}</div>;

  if (!trainingImages) {
    return <div>Not loaded yet</div>;
  }

  const count = data?.pages[0].count || "zero";
  return (
    <>
      <main className=" mx-auto px-2">
        <div className="relative z-10 flex items-baseline justify-between pt-4 pb-2 border-b border-gray-200">
          <h1 className="text-4xl font-extrabold tracking-tight text-gray-900">
            {/* Found {count} images */}
          </h1>
          <div className="flex items-center">
            <div> Found {count} images</div>
          </div>
        </div>

        <section className="pt-2 h-full">
          <div className="grid grid-cols-4 gap-x-8 gap-y-10">
            {/* Filters */}

            <form className="">
              <div className="border-b border-gray-200 pb-2">
                <h3 className="text">
                  <span className="text-xs bg-green-300 inline-block px-3 mt-2 rounded-lg">
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    💡 Type "tag1 OR tag2" to add "OR" filters
                  </span>{" "}
                </h3>
                <TagPicker
                  onTagChange={onTagChange}
                  initialTags={initialTags}
                ></TagPicker>
              </div>

              <div className="border-b border-gray-200 pb-2">
                {/* <DatasetPicker
                  onDatasetChange={onTagChange}
                  initialTags={initialTags}
                ></DatasetPicker> */}
              </div>

              {filters.map((section) => (
                <Disclosure
                  as="div"
                  key={section.id}
                  className="border-b border-gray-200 py-6"
                >
                  {({ open }) => (
                    <>
                      <h3 className="-my-3 flow-root">
                        <Disclosure.Button className="py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400 hover:text-gray-500">
                          <span className="font-medium text-gray-900">
                            {section.name}
                          </span>
                          <span className="ml-6 flex items-center">
                            {open ? (
                              <MinusSmIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            ) : (
                              <PlusSmIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            )}
                          </span>
                        </Disclosure.Button>
                      </h3>
                      <Disclosure.Panel className="pt-6">
                        <div className="space-y-4">
                          {section.options.map((option, optionIdx) => (
                            <div
                              key={option.value}
                              className="flex items-center"
                            >
                              <input
                                id={`filter-${section.id}-${optionIdx}`}
                                name={`${section.id}[]`}
                                defaultValue={option.value}
                                type="checkbox"
                                defaultChecked={option.checked}
                                className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                              />
                              <label
                                htmlFor={`filter-${section.id}-${optionIdx}`}
                                className="ml-3 text-sm text-gray-600"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </form>

            <div className="col-span-3">
              <div className="h-full w-full bg-red flex grow">
                <ReactWindowGrid
                  hasNextPage={hasNextPage || false}
                  isNextPageLoading={isFetchingNextPage}
                  items={trainingImages}
                  onItemClicked={() => {
                    console.log("clicked");
                  }}
                  loadNextPage={loadMoreItemsImageGrid}
                ></ReactWindowGrid>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
