import React, { useEffect, useRef } from "react";
import { useElementSize } from "usehooks-ts";
import Konva from "konva";
import logger from "lib/logger";
import { Vector2d } from "konva/lib/types";
import { loadImageFromSrc, redToAlpha } from "helpers/imageHelpers";
import debounce from "lodash/debounce";
import { Stage } from "react-konva";
import { Layer } from "konva/lib/Layer";

interface PropsBis {
  referenceImageURL: string;
  masksURL: string[];
}

export default function MaskVisualizer({
  referenceImageURL,
  masksURL,
}: PropsBis) {
  const stageRef1 = useRef<Konva.Stage>(null);
  const [stageWrapper, { width: stageWidth }] = useElementSize();

  // Utils function, could be moved to a helper ===========
  const getImageLayer = () => {
    return stageRef1.current?.getChildren()[0];
  };

  const handleOnWheel = (e: Konva.KonvaEventObject<WheelEvent>) => {
    const scaleBy = 200.0;

    const target = stageRef1.current;
    if (!target) {
      logger.error("Stage not found");
      return;
    }

    const oldScale = target.scaleX();
    const pointer = target.getPointerPosition();

    if (!pointer) {
      logger.error("pointer not found");
      return;
    }

    const mousePointTo = {
      x: (pointer.x - target.x()) / oldScale,
      y: (pointer.y - target.y()) / oldScale,
    };

    let newScale = oldScale - e.evt.deltaY / scaleBy;
    newScale = Math.max(newScale, 0.01);

    const handleZoomForStage = (
      stage: Konva.Stage,
      newScale: number,
      pointer: Vector2d,
      mousePointTo: { x: number; y: number }
    ) => {
      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      };

      stage.position(newPos);
    };

    handleZoomForStage(target, newScale, pointer, mousePointTo);
  };

  const resetZoom = useRef<() => void>(() => ({}));

  useEffect(() => {
    // This function can be called again before resolving all its promises
    // resulting in a race condition, so this boolean exists to know if the cleanup() function has been called before
    // all promises get resolved
    let shouldRenderToCanvas = false;

    async function displayImageAndPredictions() {
      if (!referenceImageURL || !masksURL.length || stageWidth === 0) {
        return;
      }

      const imageUrl = referenceImageURL;
      const image = await loadImageFromSrc(imageUrl);

      const predictionsPromises = masksURL.map((maskUrl: string) =>
        loadImageFromSrc(maskUrl)
      );

      const predictionMasks = await Promise.all(predictionsPromises);
      if (shouldRenderToCanvas) {
        logger.debug(
          "New images were requests since we started loading images from our scope, we are skipping the drawing part"
        );
        return;
      }
      let composeLayer: Layer | undefined = getImageLayer();

      if (!composeLayer) {
        // TODO: (eliot) find a cleaner way to do a lazy init
        composeLayer = new Konva.Layer({ imageSmoothingEnabled: false });
        composeLayer.hitGraphEnabled(false); // important otherwise it's slow on Firefox
        stageRef1.current?.add(composeLayer);
      }
      composeLayer?.removeChildren();

      const originalImage = new Konva.Image({
        image: image,
        x: 0,
        y: 0,
      });

      composeLayer?.add(originalImage);
      predictionMasks.forEach((predictionMask, index) => {
        const alphaCanvas = redToAlpha(predictionMask);

        const imageShape = new Konva.Shape({
          name: "name",
          x: (1 + index) * image.width,
          y: 0,
          width: image.width,
          height: image.height,
          sceneFunc: (ctx) => {
            if (!alphaCanvas) {
              logger.error("No alpha canvas data");
              return;
            }

            ctx.drawImage(alphaCanvas, 0, 0, image.width, image.height);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Eliot: no clue why this is occuring
            ctx.globalCompositeOperation = "source-in";
            ctx.drawImage(image, 0, 0, image.width, image.height);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore Eliot: no clue why this is occuring

            ctx.globalCompositeOperation = "source-over";
          },
          draggable: false,
        });

        imageShape.cache();

        composeLayer?.add(imageShape);

        resetZoom.current = () => {
          const newScale = stageWidth / (image.width * (masksURL.length + 1));
          stageRef1.current?.scale({ x: newScale, y: newScale });
          stageRef1.current?.position({ x: 0, y: 0 });
        };

        resetZoom.current();
      });
    }

    // This is basically just a debounce() for a smoother UX (along with the clearTimeout in the cleanup function)
    // This setTimeout is not here to fix the race condition handled by shouldRenderToCanvas
    const timeout = setTimeout(displayImageAndPredictions, 100);

    return () => {
      shouldRenderToCanvas = true;
      clearTimeout(timeout);
    };
  }, [stageWidth, referenceImageURL, masksURL]);

  // TODO: Use proper hook library for handling keyboard.
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "g":
          resetZoom.current();
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div className="bg-red-600 flex-grow h-screen">
      <div className="stage-wrapper" ref={stageWrapper}>
        <div id="stage-parent">
          <Stage
            width={stageWidth}
            // the 151 offset is coming from the height of the image slidebar + 1px to show the limit of the stage
            height={window.innerHeight - 151}
            ref={stageRef1}
            onWheel={debounce(handleOnWheel)}
            draggable
          ></Stage>
        </div>
      </div>
    </div>
  );
}
