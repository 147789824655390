import React from "react";

export function backgroundColorToStyle(backgroundColor: string, size = 20) {
  if (backgroundColor === "checkerboard") {
    return {
      backgroundColor: "#dddddd",
      backgroundImage:
        "linear-gradient(45deg, #aaaaaa 25%, transparent 25%), linear-gradient(-45deg, #aaaaaa 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #aaaaaa 75%), linear-gradient(-45deg, transparent 75%, #aaaaaa 75%)",
      backgroundSize: `${size * 2}px ${size * 2}px`,
      backgroundPosition: `0 0, 0 ${size}px, ${size}px -${size}px, -${size}px 0px`,
    };
  } else {
    return {
      backgroundColor,
    };
  }
}

function ColorButton({
  color,
  isSelected,
  onClick,
}: {
  color: string;
  isSelected: boolean;
  onClick: () => void;
}) {
  console.log(backgroundColorToStyle(color));
  return (
    <button
      className={
        "rounded-full w-6 h-6 " +
        (isSelected && "outline-2 outline outline-white")
      }
      style={backgroundColorToStyle(color, 12)}
      onClick={onClick}
    />
  );
}

// Colors shamelessly copied from https://casesandberg.github.io/react-color/ circle color picker.
export const colors = [
  "checkerboard",
  "#F44336",
  "#E91E63",
  "#9C27B0",
  "#673AB7",
  "#3F51B5",
  "#2196F3",
  "#03A9F4",
  "#00BCD4",
  "#009688",
  "#4CAF50",
  "#8BC34A",
  "#CDDC39",
  "#FFEB3B",
  "#FFC107",
  "#FF9800",
  "#FF5722",
  "#795548",
  "#607D8B",
  "#000000",
  "#FFFFFF",
];

export default function SimpleColorPicker({
  selectedColor,
  onChange,
}: {
  selectedColor: string;
  onChange: (color: string) => void;
}) {
  return (
    <div className="grid grid-cols-7 gap-4">
      {colors.map((color) => (
        <ColorButton
          key={color}
          color={color}
          isSelected={color === selectedColor}
          onClick={() => onChange(color)}
        />
      ))}
    </div>
  );
}
