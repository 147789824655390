import { Navigate } from "@tanstack/react-location";
import React, { ReactNode, useMemo, useRef } from "react";
import { ReactNotifications } from "react-notifications-component";
import LoadingBar, { LoadingBarRef } from "react-top-loading-bar";
import { useIsFetching } from "react-query";
// How many queries are fetching?
import Loader from "components/Loader";
import { useFirebaseUser } from "hooks/useFirebaseUser";
import { NavBar } from "components/NavBar";

interface Props {
  children: ReactNode;
  requiresAuthentication?: boolean;
  showNavbar?: boolean;
}

export default function AppLayout({
  children,
  requiresAuthentication = true,
  showNavbar = true,
}: Props): JSX.Element {
  const ref = useRef<LoadingBarRef>(null);
  const topBarLoading = useRef(false);
  const isFetching = useIsFetching();

  if (isFetching && !topBarLoading.current) {
    topBarLoading.current = true;
    setTimeout(() => {
      // Added to prevent an error in the console
      ref?.current?.continuousStart(10, 500);
    });
  } else {
    topBarLoading.current = false;
    setTimeout(() => {
      // Added to prevent an error in the console
      ref?.current?.complete();
    });
  }
  return (
    <>
      <ReactNotifications />

      <div className="flex min-h-screen flex-col bg-gray-50">
        <BootStrap requiresAuthentication={requiresAuthentication}>
          <main
            className="flex flex-1 flex-col bg-center pt-[var(--app-header-height)] bg-fixed"
            style={
              {
                // backgroundImage: `url("${backgroundImage}")`,
              }
            }
          >
            <LoadingBar
              shadow={false}
              color="#410CD9"
              waitingTime={200}
              ref={ref}
            />
            {showNavbar && <NavBar />}
            <div className={showNavbar ? "pt-14" : ""}>{children}</div>
          </main>
        </BootStrap>
      </div>
    </>
  );
}

interface BootStrapProps {
  children: ReactNode;
  requiresAuthentication: boolean;
}

function BootStrap({ children, requiresAuthentication }: BootStrapProps) {
  const [firebaseUser, firebaseUserLoading] = useFirebaseUser();

  const shouldRedirect = useMemo(() => {
    return (
      requiresAuthentication &&
      !firebaseUserLoading &&
      (!firebaseUser || firebaseUser?.isAnonymous)
    );
  }, [requiresAuthentication, firebaseUser, firebaseUserLoading]);

  if (firebaseUserLoading) {
    return <Loader fullScreen size="large" />;
  }

  return shouldRedirect ? (
    <Navigate to="/login" replace fromCurrent />
  ) : (
    <>{children}</>
  );
}
