import { APIResponse, Dataset, Tag } from "@customTypes/data";
import { datasetsUrl, DATASET_QUERY_IDENTIFIER, tagsUrl } from "../constants";
import { TAGS_QUERY_IDENTIFIER } from "../constants";
import { useInfiniteQuery, useQuery } from "react-query";
import { djangoBackend } from "services/apiServices";

const DATASET_PAGE_SIZE = 5000;
export const useDatasets = () =>
  useInfiniteQuery<APIResponse<Dataset>>(
    [DATASET_QUERY_IDENTIFIER],
    ({ pageParam = 1 }) => {
      const url = `${datasetsUrl}?page_size=${DATASET_PAGE_SIZE}&page=${pageParam}`;
      return djangoBackend.get(url).then((res) => {
        return res.data;
      });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next ? pages.length + 1 : undefined;
      },
    }
  );
