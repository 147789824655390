import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { djangoBackend } from "services/apiServices";
import { apiKeysUrl } from "../constants";
import { ApiKey } from "@customTypes/data";
import React from "react";

export function ApiKeyView() {
  // @todo(aric): move the data fetching to the profile container and make this container a component
  const { isLoading, error, data } = useQuery(
    "myapikey",
    (): Promise<ApiKey> =>
      djangoBackend.get(apiKeysUrl).then((res) => {
        return res.data.results[0];
      })
  );

  if (isLoading) return <div>Loading...</div>;

  if (error && error instanceof Error)
    return <div>An error has occurred: {error.message}</div>;

  if (!data) {
    return <div>Not loaded yet</div>;
  } else {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Your profile
          </h3>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Api Key</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {data.key}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    );
  }
}
