import { APIResponse, Dataset } from "@customTypes/data";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationIcon } from "@heroicons/react/outline";
import { TrashIcon } from "@heroicons/react/solid";
import { useNavigate } from "@tanstack/react-location";
import { useDatasets } from "hooks/useDatasets";
import React, { Fragment, useCallback, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { djangoBackend } from "services/apiServices";
import { datasetsUrl, DATASET_QUERY_IDENTIFIER } from "../constants";
import AppLayout from "./Layouts/AppLayout";

const DATASET_PAGE_SIZE = 20;

export default function DatasetList() {
  return (
    <AppLayout requiresAuthentication={true}>
      <DatasetListInternal />
    </AppLayout>
  );
}
function DatasetListInternal() {
  const navigate = useNavigate();
  const [deleteAlertOpen, setDeleteAlertOpen] = useState<string | null>(null);

  const {
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    data,
    fetchNextPage,
    error,
    refetch,
  } = useDatasets();

  const datasets = data?.pages.flatMap(
    (datasetResponse: APIResponse<Dataset>) => {
      return datasetResponse.results;
    }
  );

  const deleteDataset = useCallback(
    async (datasetId: string) => {
      await djangoBackend.delete(`${datasetsUrl}${datasetId}`);
      await refetch();
    },
    [refetch]
  );

  if (isLoading) return <div>Loading...</div>;

  if (error && error instanceof Error)
    return <div>An error has occurred: {error.message}</div>;

  if (!data) {
    return <div>Not loaded yet</div>;
  } else {
    return (
      <>
        <DeleteAlert
          datasetId={deleteAlertOpen}
          setOpen={() => {
            setDeleteAlertOpen(null);
          }}
          confirmAction={deleteDataset}
        />
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">Datasets</h1>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Dataset Id
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Author
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Draft Staus
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {datasets?.map((dataset: Dataset, datasetIdx) => {
                        return (
                          <tr
                            key={dataset.id}
                            className={
                              datasetIdx % 2 === 0 ? undefined : "bg-gray-50"
                            }
                          >
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                              {dataset.id}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {dataset.author.email}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {dataset.isDraft ? "Draft" : "Locked"}
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                              <span className="relative z-0 inline-flex shadow-sm rounded-md">
                                <button
                                  onClick={() =>
                                    navigate({ to: `/dataset/${dataset.id}` })
                                  }
                                  type="button"
                                  className={`relative inline-flex items-center px-4 py-2 ${
                                    dataset.canDelete
                                      ? "rounded-l-md"
                                      : "rounded"
                                  } border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500`}
                                >
                                  View
                                </button>
                                {dataset.canDelete && (
                                  <button
                                    type="button"
                                    className="-ml-px relative inline-flex items-center px-3 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                                    onClick={() =>
                                      setDeleteAlertOpen(dataset.id)
                                    }
                                  >
                                    <TrashIcon
                                      className="h-5 w-5 text-red-400"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )}
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="text-center mt-5">
                  {hasNextPage && (
                    <button
                      onClick={() => fetchNextPage()}
                      type="button"
                      className="content-center	 inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Load More
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

interface alertProps {
  datasetId: string | null;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  confirmAction: (id: string) => void;
}

export function DeleteAlert({ datasetId, setOpen, confirmAction }: alertProps) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={!!datasetId} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Delete dataset {datasetId}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete the dataset{" "}
                        <strong>{datasetId}</strong> ?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    if (datasetId) {
                      confirmAction(datasetId);
                    }
                    setOpen(false);
                  }}
                >
                  Detele {datasetId}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
