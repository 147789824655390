import axios from "axios";
import { QueryClient } from "react-query";

import { backendUrl, EXPLORE_PAGE_SIZE, trainingImagesUrl } from "../constants";
import { getIdToken } from "services/firebaseService";
import logger from "lib/logger";

export const imagesQueryIdentifier = "images";

export const djangoBackend = axios.create({
  baseURL: backendUrl,
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

djangoBackend.interceptors.request.use(
  async (config) => {
    const firebaseToken = await getIdToken();

    if (config.headers && firebaseToken) {
      config.headers.authorization = firebaseToken;
    } else {
      logger.error("Could not set firebaseToken", firebaseToken);
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export function buildImageQueryUrl(tags: string[], pageParam: string) {
  let url = `${trainingImagesUrl}?page_size=${EXPLORE_PAGE_SIZE}&page=${pageParam}&ordering=-createdAt`;

  const tagFilteringString = tags
    .map((tag) => {
      if (tag.includes(" OR ")) {
        const orTags = tag.split(" OR ");
        const orTagString = orTags.join(",");
        return `(tagAnnotations__tag__in=${orTagString})`;
      }
      if (tag.startsWith("NOT ")) {
        return `~(tagAnnotations__tag=${tag.replace("NOT ", "")})`;
      }
      return `(tagAnnotations__tag=${tag})`;
    })
    .join("%26"); // Important: don't join with "&"

  if (tagFilteringString.length > 0) {
    url += "&filters=" + tagFilteringString;
  }
  return url;
}
