import {
  ChartBarIcon,
  OfficeBuildingIcon,
  TagIcon,
  SearchIcon,
  EyeIcon,
} from "@heroicons/react/solid";
import { useMatch, useNavigate } from "@tanstack/react-location";
import classNames from "classnames";
import { auth } from "services/firebaseService";
import React from "react";
interface TabConfig {
  name: string;
  href: string;
  icon: (props: any) => JSX.Element;
  isCurrentRegexp: RegExp;
  navigateTo: string;
  navigateSearch?: object;
}

const mainTabs: TabConfig[] = [
  {
    name: "Explore 🗺",
    href: "#",
    icon: SearchIcon,
    isCurrentRegexp: /^\/explore/,
    navigateTo: "/explore",
    navigateSearch: {},
  },
  {
    name: "Compare 👀",
    href: "#",
    icon: EyeIcon,
    isCurrentRegexp: /^\/compare/,
    navigateTo: "/compare",
    navigateSearch: {},
  },
];
const secondaryTabs: TabConfig[] = [
  {
    name: "Datasets",
    href: "#",
    icon: OfficeBuildingIcon,
    isCurrentRegexp: /^\/dataset/,
    navigateTo: "/dataset",
    navigateSearch: {},
  },
  {
    name: "Prediction Runs",
    href: "#",
    icon: ChartBarIcon,
    isCurrentRegexp: /^\/predictions/,
    navigateTo: "/predictions",
    navigateSearch: {},
  },

  {
    name: "Tags 🏷",
    href: "#",
    icon: TagIcon,
    isCurrentRegexp: /^\/tags/,
    navigateTo: "/tags",
    navigateSearch: {},
  },
];

function Tab({ tab }: { tab: TabConfig }): JSX.Element {
  const navigate = useNavigate();
  const { route } = useMatch();

  return (
    <button
      key={tab.name}
      onClick={() =>
        navigate({ to: tab.navigateTo, search: tab.navigateSearch })
      }
      className={classNames(
        route.id && tab.isCurrentRegexp.test(route.id)
          ? "border-indigo-500 text-indigo-600"
          : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
        "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm"
      )}
      aria-current={
        route.id && tab.isCurrentRegexp.test(route.id) ? "page" : undefined
      }
    >
      <tab.icon
        className={classNames(
          route.id && tab.isCurrentRegexp.test(route.id)
            ? "text-indigo-500"
            : "text-gray-400 group-hover:text-gray-500",
          "-ml-0.5 mr-2 h-5 w-5"
        )}
        aria-hidden="true"
      />
      <span>{tab.name}</span>
    </button>
  );
}
export function NavBar() {
  const navigate = useNavigate();

  const logout = () => {
    console.log("Logout");
    auth.signOut();
  };

  const showProfile = () => {
    navigate({ to: "/profile" });
  };

  return (
    <div className="px-2 bg-white fixed top-0 left-0 right-0 z-20">
      <div className="hidden sm:block">
        <div className="border-b border-gray-200 flex align-middle justify-center items-center">
          <nav className="-mb-px flex space-x-8 flex-grow" aria-label="Tabs">
            {mainTabs.map((tab) => (
              <Tab key={tab.name} tab={tab}></Tab>
            ))}
            <span className="w-1 bg-gray-500 h-full"></span>
            {secondaryTabs.map((tab) => (
              <Tab key={tab.name} tab={tab}></Tab>
            ))}
          </nav>
          <button
            onClick={showProfile}
            className="ml-6 h-8 inline-flex items-center px-4 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Profile
          </button>
          <button
            onClick={logout}
            className="ml-6 h-8 inline-flex items-center px-4 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}
