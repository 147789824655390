import { AuthError, signInWithEmailAndPassword } from "@firebase/auth";
import { Navigate, useNavigate, useSearch } from "@tanstack/react-location";

import AppLayout from "containers/Layouts/AppLayout";

import logger from "lib/logger";
import { addNotification } from "lib/notification";

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import React, { useContext, useState } from "react";
import { useFirebaseUser } from "hooks/useFirebaseUser";
import Loader from "components/Loader";

export default function Login(): JSX.Element {
  const [firebaseUser, firebaseUserLoading] = useFirebaseUser();

  const signInWithGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        // The signed-in user info.
        const user = result.user;
        console.log("user", user);
        addNotification("Sign-in success!");
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.email;
        // The AuthCredential type that was used.
        // ...
      });
  };

  if (firebaseUser !== null && firebaseUser.isAnonymous === false) {
    return <Navigate to={"/"} search={{}} replace fromCurrent />;
  }

  if (firebaseUserLoading) {
    return <Loader fullScreen size="large" />;
  }

  return (
    <AppLayout requiresAuthentication={false}>
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <button
              type="submit"
              onClick={signInWithGoogle}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Sign in with Google
            </button>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
