import {
  APIResponse,
  MaskAnnotation,
  Prediction,
  PredictionsResponse,
  TrainingImage,
} from "@customTypes/data";
import {
  useMatch,
  MakeGenerics,
  useSearch,
  useNavigate,
} from "@tanstack/react-location";
import Modal from "components/ImageModal";
import MaskVisualizer from "components/MaskVisualizer";
import ImagesSlidebar from "components/ImagesSlidebar";
import React, { useState, useRef, useEffect, useCallback } from "react";

import { useInfiniteQuery } from "react-query";
import { djangoBackend } from "services/apiServices";
import { DATASET_QUERY_IDENTIFIER, maskAnnotationsUrl } from "../constants";
import AppLayout from "./Layouts/AppLayout";
import NewImagesGrid from "./NewImagesGrid";
import debounce from "lodash/debounce";
import { loadImageFromSrc } from "helpers/imageHelpers";
import ImageSidebar from "components/ImageSidebar";
import { InformationCircleIcon } from "@heroicons/react/outline";

const TEMPLATES_PAGE_SIZE = 20;
const FIRST_PAGE = 1;

type MyLocationGenerics = MakeGenerics<{
  Search: {
    selectedIndex: number;
  };
}>;

export default function DatasetDetails() {
  const {
    params: { datasetId },
  } = useMatch();
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    undefined
  );

  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const search = useSearch<MyLocationGenerics>();
  const slidebarRef = useRef(null);
  let totalCount = 1000;
  const masonryUniqueKey = `dataset-${datasetId}`;
  const selectedIndexURI = search.selectedIndex;

  const navigateHelper = useCallback(
    (selectedIndex: number | undefined) => {
      navigate({ search: { selectedIndex } });
    },
    [navigate]
  );

  useEffect(() => {
    setSelectedIndex(selectedIndexURI);
  }, [selectedIndexURI]);

  useEffect(() => {
    if (selectedIndex !== null) {
      const timeout = setTimeout(() => {
        if (slidebarRef.current) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          slidebarRef.current._listRef.scrollToItem(selectedIndex, "center");
        }
      }, 0);

      return () => clearTimeout(timeout);
    }
  }, [selectedIndex]);

  const fetchPredictions = async ({ pageParam = FIRST_PAGE }) => {
    const url = `${maskAnnotationsUrl}?dataset=${datasetId}&page=${pageParam}&page_size=${TEMPLATES_PAGE_SIZE}`;
    const response = await djangoBackend.get(url);
    return response.data;
  };

  // TODO: (eliot) handle error state / retry
  const { isLoading, hasNextPage, isFetchingNextPage, data, fetchNextPage } =
    useInfiniteQuery<APIResponse<MaskAnnotation>>(
      [DATASET_QUERY_IDENTIFIER, datasetId],
      fetchPredictions,
      {
        getNextPageParam: (lastPage, pages) => {
          return lastPage.next ? pages.length + 1 : undefined;
        },
      }
    );
  if (data?.pages[0]) totalCount = data.pages[0].count;

  const maskAnnotations: MaskAnnotation[] | undefined = data?.pages.flatMap(
    (predictionResponse: APIResponse<MaskAnnotation>) => {
      return predictionResponse.results.map((result) => {
        return result;
      });
    }
  );

  const trainingImages = maskAnnotations?.map(
    (maskAnnotation: MaskAnnotation) => {
      return maskAnnotation.image;
    }
  );

  function Row(props: { index: number; style: React.CSSProperties }) {
    useEffect(() => {
      // We are prefeching full res image and masks for the selectedIndex +/- 1 here,
      // but only if the instance of this component stayed the +/- 1 for more than
      // 100 miliseconds.
      // This prevents pre-loading full res image and masks of all images when
      // scrolling quickly.
      const timeout = setTimeout(() => {
        if (trainingImages !== undefined && trainingImages.length > 0) {
          if (
            selectedIndex !== undefined &&
            Math.abs(props.index - selectedIndex) === 1
          ) {
            loadImageFromSrc(trainingImages[props.index].imageUrlFullSize);
            if (maskAnnotations && maskAnnotations[props.index]) {
              loadImageFromSrc(maskAnnotations[props.index].maskUrl);
            }
          }
        }
      }, 100);

      return () => clearTimeout(timeout);
    }, [props.index]);

    if (!trainingImages) {
      return <div style={props.style}>No training image yet</div>;
    }

    if (props.index > totalCount) {
      return <div style={props.style}>No more images to display</div>;
    }
    if (trainingImages[props.index]) {
      let highlightClass = "";
      if (props.index === selectedIndex) {
        highlightClass = "border-solid border-4 border-blue-600";
      }
      return (
        <div
          className={`${highlightClass} bg-white cursor-pointer p-1 overflow-hidden`}
          style={props.style}
          onClick={() => onItemClicked(props.index)}
        >
          {/* <div style={{position:'absolute', top:0, left:0, width:30, height:30, background:'white'}}>{props.index}</div> */}
          <img
            src={trainingImages[props.index].imageUrl150x150}
            alt={trainingImages[props.index].id}
          />
        </div>
      );
    } else {
      return <div style={props.style}>Image not loaded yet</div>;
    }
  }
  const fetchMoreItems = useCallback(
    (pageParam: number | null) => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage({ cancelRefetch: false, pageParam: pageParam });
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage]
  );

  const isItemLoaded = useCallback(
    (index: number) => (trainingImages ? !!trainingImages[index] : false),
    [trainingImages]
  );
  const loadMoreItemsSlideBar = useCallback(
    async (startIndex: number, stopIndex: number) => {
      let previouslyLoaded = -1;

      for (let i = startIndex; i <= stopIndex; i++) {
        const pageToLoad = Math.floor(i / TEMPLATES_PAGE_SIZE) + 1;
        if (pageToLoad !== previouslyLoaded) {
          previouslyLoaded = pageToLoad;
          await fetchMoreItems(pageToLoad);
        }
      }
    },
    [fetchMoreItems]
  );

  const loadMoreItemsImageGrid = useCallback(
    (startIndex: number) => {
      const pageToLoad = Math.floor(startIndex / TEMPLATES_PAGE_SIZE) + 1;
      fetchMoreItems(pageToLoad);
    },
    [fetchMoreItems]
  );

  const onItemClicked = useCallback(
    (index: number) => {
      navigateHelper(index);
    },
    [navigateHelper]
  );

  const onNextItemSelected = useCallback(() => {
    if (selectedIndex !== undefined && selectedIndex + 1 < totalCount) {
      navigateHelper(selectedIndex + 1);
    }
  }, [navigateHelper, selectedIndex, totalCount]);

  const onPreviousItemSelected = useCallback(() => {
    if (selectedIndex !== undefined && selectedIndex > 0) {
      navigateHelper(selectedIndex - 1);
    }
  }, [navigateHelper, selectedIndex]);

  let referenceImageUrl = null;
  let masksUrl: string[] = [];

  if (
    selectedIndex !== undefined &&
    maskAnnotations &&
    maskAnnotations[selectedIndex]
  ) {
    referenceImageUrl = maskAnnotations[selectedIndex].image.imageUrlFullSize;
    masksUrl = [maskAnnotations[selectedIndex].maskUrl];
  }

  return (
    <AppLayout requiresAuthentication={true}>
      <Modal
        isOpen={selectedIndex != null}
        setIsOpen={() => navigateHelper(undefined)}
        fullScreen={true}
        className="pb-100"
      >
        <div className="flex flex-row">
          {/* Prediction Renderer */}

          {referenceImageUrl && masksUrl.length > 0 && (
            <>
              <MaskVisualizer
                referenceImageURL={referenceImageUrl}
                masksURL={masksUrl}
              />
              <button
                className="absolute top-5 right-5"
                onClick={() => setSidebarOpen(true)}
              >
                <InformationCircleIcon className="h-6 w-6 text-white m-0 " />
              </button>
            </>
          )}

          {/* Details sidebar */}
          {/* <div className=''>
          {referencePrediction && <SideBar selectedImage={referencePrediction?.image} />}
        </div> */}
        </div>

        <div className="fixed bottom-0 left-0 right-0 bg-white">
          <ImagesSlidebar
            isItemLoaded={isItemLoaded}
            totalCount={totalCount}
            loadMoreItems={loadMoreItemsSlideBar}
            elementRef={slidebarRef}
            row={Row}
            onPreviousItemSelected={debounce(onPreviousItemSelected)}
            onNextItemSelected={debounce(onNextItemSelected)}
          />
        </div>
        {sidebarOpen &&
          selectedIndex &&
          trainingImages &&
          trainingImages[selectedIndex] && (
            <div
              style={{ marginTop: "0" }}
              className="fixed top-0 right-0 mt-0 h-full"
            >
              <ImageSidebar
                setSidebarOpen={setSidebarOpen}
                trainingImage={trainingImages[selectedIndex]}
              />
            </div>
          )}
      </Modal>
      {isLoading && <h1>Loading data</h1>}
      {trainingImages && trainingImages.length > 0 && (
        <NewImagesGrid
          fetchMoreItems={loadMoreItemsImageGrid}
          trainingImages={trainingImages}
          onItemClicked={onItemClicked}
          masonryUniqueKey={masonryUniqueKey}
        />
      )}
    </AppLayout>
  );
}
