import logger from "lib/logger";

export function loadImageFromSrc(src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.addEventListener("load", () => {
      URL.revokeObjectURL(img.src);
      resolve(img);
    });
    img.addEventListener("error", (err) => {
      URL.revokeObjectURL(img.src);
      reject(err);
    });
    img.src = src;
  });
}

// Warning: (eliot) this is really slow,
// TODO: implement this solution: https://stackoverflow.com/a/44192426
export function redToAlpha(
  image: HTMLImageElement
): HTMLCanvasElement | undefined {
  const canvas = document.createElement("canvas");
  canvas.width = image.width;
  canvas.height = image.height;

  const canvasContext = canvas.getContext("2d");
  canvasContext?.drawImage(image, 0, 0);

  const contextData = canvasContext?.getImageData(
    0,
    0,
    image.width,
    image.height
  );
  const imageData = contextData?.data;
  if (!imageData) {
    logger.error("No image data");
    return;
  }

  for (let i = 0, n = imageData.length; i < n; i += 4) {
    // Use the red value but multiply it by alpha in case alpha is defined
    imageData[i + 3] = (imageData[i] * imageData[i + 3]) / 255.0;
  }

  if (!contextData) {
    logger.error("No context data");
    return;
  }
  canvasContext?.putImageData(contextData, 0, 0);

  return canvas;
}
