import { ApiKeyView } from "./ApiKeyView";
import AppLayout from "./Layouts/AppLayout";
import React from "react";

export function UserProfile() {
  return (
    <AppLayout requiresAuthentication={true}>
      <ApiKeyView />
    </AppLayout>
  );
}
