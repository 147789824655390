import { APIResponse, Tag } from "@customTypes/data";
import { useInfiniteQuery } from "react-query";
import { ActionMeta, createFilter, OnChangeValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import { djangoBackend } from "services/apiServices";
import {
  predictionRunsUrl,
  PREDICTION_RUN_QUERY_IDENTIFIER,
} from "../constants";
import React, { useEffect, useState } from "react";
interface SelectOption {
  readonly value: string;
  readonly label: string;
}
export function PredictionRunPicker({
  onPredictionRunChange,
  selectedPredictionRuns: initialPredictionRuns,
  dataset,
}: {
  onPredictionRunChange: (predictionRuns: string[]) => void;
  selectedPredictionRuns: string[];
  dataset: string | undefined;
}): JSX.Element {
  const selectedPredictionRunOptions = initialPredictionRuns?.map((run) => {
    return { value: run, label: run } as SelectOption;
  });

  if (!dataset) {
    return (
      <div className="block text-sm font-medium text-gray-700 mt-7">
        Please select a dataset
      </div>
    );
  }

  const {
    isLoading,
    error: predictionRunError,
    data: datasetPages,
  } = useInfiniteQuery<APIResponse<Tag>>(
    [PREDICTION_RUN_QUERY_IDENTIFIER, dataset],
    ({ pageParam = 1 }) => {
      // TODO: add proper fetching of all the pages
      const url = `${predictionRunsUrl}?page_size=${5000}&page=${pageParam}&dataset=${dataset}`;
      return djangoBackend.get(url).then((res) => {
        return res.data;
      });
    },
    {
      getNextPageParam: (lastPage, pages) => {
        return lastPage.next ? pages.length + 1 : undefined;
      },
    }
  );
  const datasetData = datasetPages?.pages.flatMap((page) => {
    return page.results.map((tag) => tag.id);
  });
  const handleChangePredictionRun = (
    newValue: OnChangeValue<SelectOption, true>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    console.log(`action: ${actionMeta.action}`);
    onPredictionRunChange(newValue.map((option) => option.value));
  };

  if (predictionRunError) {
    return <div>Error: {predictionRunError}</div>;
  }

  const predictionRunOptions = datasetData?.map((run) => {
    return { value: run, label: run };
  });

  return (
    <div className="">
      <span className="block text-sm font-medium text-gray-700">
        Prediction runs
        {isLoading ? "..." : ` (${predictionRunOptions?.length})`}
      </span>
      <CreatableSelect
        filterOption={createFilter({ ignoreAccents: false })} // Speed optimisation
        isMulti
        onChange={handleChangePredictionRun}
        options={predictionRunOptions}
        isLoading={isLoading}
        value={selectedPredictionRunOptions}
      />
    </div>
  );
}
