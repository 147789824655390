export const backendUrl =
  process.env.REACT_APP_BACKEND_URL || "https://dataroom-backend.artizans.ai";

export const trainingImagesUrl = "images/";
export const predictionsUrl = "predictions/";
export const predictionRunsUrl = "prediction-runs/";
export const apiKeysUrl = "apikeys/";
export const tagsUrl = "tags/";
export const datasetsUrl = "datasets/";
export const maskAnnotationsUrl = "mask-annotations/";
export const EXPLORE_PAGE_SIZE = 20;

export const DATASET_QUERY_IDENTIFIER = "datasets";
export const TAGS_QUERY_IDENTIFIER = "tags";
export const PREDICTION_RUN_QUERY_IDENTIFIER = "prediction_run";
export const PREDICTION_QUERY_IDENTIFIER = "prediction";
