import classNames from "classnames";
import React from "react";
import styles from "./Loader.module.css";

const sizes = {
  "x-small": "1rem",
  small: "3rem",
  medium: "6rem",
  large: "8rem",
  ratio: "40vh",
};

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  size?: "x-small" | "small" | "medium" | "large" | "ratio" | number;
  wrapperClasses?: string;
  fullScreen?: boolean;
  color?: "default" | "white";
}

export default function Loader({
  size = "small",
  wrapperClasses = "",
  color = "default",
  fullScreen,
  ...rest
}: Props): JSX.Element {
  const width = typeof size === "number" ? size : sizes[size];
  const height = width;

  return (
    <div
      className={classNames([
        // absolute is here to center the loader inside its container
        // container has to declared as relative for this to work
        fullScreen
          ? "fixed top-0 bottom-0 left-0 right-0 h-screen w-screen"
          : "absolute h-full w-full",
        "flex items-center justify-center",
        wrapperClasses,
      ])}
    >
      <div
        className={styles.loader}
        style={{
          width,
          height,
          ...rest.style,
        }}
      >
        <svg
          className={styles.circularLoader}
          viewBox="25 25 50 50"
          width={size !== "ratio" ? width : undefined}
          height={size !== "ratio" ? height : undefined}
        >
          <circle
            className={
              color === "default" ? styles.loaderPath : styles.loaderPathWhite
            }
            cx="50"
            cy="50"
            r="20"
          ></circle>
        </svg>
      </div>
    </div>
  );
}
