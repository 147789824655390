import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

export default function ShortcutsModal({
  shortcuts,
  isOpen,
  onClose,
}: {
  shortcuts: {
    [key: string]: string;
  };
  isOpen: boolean;
  onClose: () => void;
}) {
  const shortcutsList = Object.entries(shortcuts).map(([key, value]) => {
    return (
      <tr key={key} className="mx-6 border-b border-slate-100 text-slate-500">
        <td className="py-4 px-6">{key}</td>
        <td className="py-4 px-6">{value}</td>
      </tr>
    );
  });

  const content = (
    <table className="w-full   rounded-b-2xl overflow-hidden">
      <thead className="text-slate-400 text-left font-medium">
        <tr>
          <th className="py-4 px-6">Shortcut</th>
          <th className="py-4 px-6">Action</th>
        </tr>
      </thead>
      <tbody className="text-gray-700 text-left bg-white">
        {shortcutsList}
      </tbody>
    </table>
  );

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="w-full max-w-md transform rounded-2xl bg-slate-100 text-left align-middle shadow-xl transition-all">
                <div className="absolute top-0 right-0 p-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 p-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none"
                    onClick={onClose}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="mt-4">{content}</div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
