import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import Konva from "konva";
import { Vector2d } from "konva/lib/types";
import { redToAlpha } from "helpers/imageHelpers";
import debounce from "lodash/debounce";
import { Stage, Layer, Shape } from "react-konva";
import { Context } from "konva/lib/Context";
import logger from "lib/logger";
import { useElementSize } from "usehooks-ts";
import SimpleColorPicker, {
  backgroundColorToStyle,
  colors,
} from "./SimpleColorPicker";
import ShortcutsModal from "./ShortcutsModal";

interface MaskInfo {
  image: HTMLImageElement | undefined;
  mask: HTMLImageElement | null | undefined;
  label: string;
}

export enum MaskingMode {
  MASK_ONLY = "MASK_ONLY",
  MASK = "MASK",
  MASK_INVERSE = "MASK_INVERSE",
}

interface ImageVisualizerRef {
  setPosition: (position: Vector2d) => void;
  setScale: (scale: number) => void;
}

interface ImageVisualizerProps {
  mask: MaskInfo;
  width: number;
  height: number;
  minimumScale: number;
  maskingMode: MaskingMode;

  onZoom: (position: Vector2d, scale: number) => void;
  onDrag: (position: Vector2d) => void;
}

const ImageVisualizer = forwardRef<ImageVisualizerRef, ImageVisualizerProps>(
  ({ mask, width, height, minimumScale, maskingMode, onZoom, onDrag }, ref) => {
    const stageRef = useRef<Konva.Stage>(null);

    const alphaCanvas = useMemo(() => {
      if (mask.mask !== undefined && mask.mask !== null) {
        return redToAlpha(mask.mask);
      }
    }, [mask.mask]);

    const handleOnWheel = (event: Konva.KonvaEventObject<WheelEvent>) => {
      const scaleBy = 200.0;

      const target = stageRef.current;
      if (target === null) {
        logger.error("Stage not found");
        return;
      }

      const oldScale = target.scaleX();
      const pointer = target.getPointerPosition();
      if (pointer === null) {
        logger.error("Pointer not found");
        return;
      }

      const mousePointTo = {
        x: (pointer.x - target.x()) / oldScale,
        y: (pointer.y - target.y()) / oldScale,
      };

      let newScale = oldScale - event.evt.deltaY / scaleBy;
      newScale = Math.max(newScale, minimumScale);

      onZoom(
        {
          x: pointer.x - mousePointTo.x * newScale,
          y: pointer.y - mousePointTo.y * newScale,
        },
        newScale
      );
    };

    useImperativeHandle(ref, () => ({
      setPosition: (position: Vector2d) => {
        stageRef.current?.position(position);
      },
      setScale: (scale: number) => {
        stageRef.current?.scale({ x: scale, y: scale });
      },
    }));

    const handleOnDragMove = (event: Konva.KonvaEventObject<DragEvent>) => {
      onDrag(event.target.position());
    };

    const sceneFunc = useCallback(
      (ctx: Context) => {
        if (mask.image !== undefined) {
          switch (maskingMode) {
            case MaskingMode.MASK_ONLY:
              if (alphaCanvas !== undefined) {
                ctx.drawImage(
                  alphaCanvas,
                  0,
                  0,
                  mask.image.width,
                  mask.image.height
                );
              }
              break;

            case MaskingMode.MASK:
              if (alphaCanvas !== undefined) {
                ctx.drawImage(
                  alphaCanvas,
                  0,
                  0,
                  mask.image.width,
                  mask.image.height
                );
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore Eliot: no clue why this is occuring
                ctx.globalCompositeOperation = "source-in";
              }

              if (mask !== null) {
                ctx.drawImage(
                  mask.image,
                  0,
                  0,
                  mask.image.width,
                  mask.image.height
                );
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore Eliot: no clue why this is occuring
                ctx.globalCompositeOperation = "source-over";
              }
              break;

            case MaskingMode.MASK_INVERSE:
              if (alphaCanvas !== undefined) {
                ctx.drawImage(
                  alphaCanvas,
                  0,
                  0,
                  mask.image.width,
                  mask.image.height
                );
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore Eliot: no clue why this is occuring
                ctx.globalCompositeOperation = "source-out";
              }

              if (mask !== null) {
                ctx.drawImage(
                  mask.image,
                  0,
                  0,
                  mask.image.width,
                  mask.image.height
                );
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore Eliot: no clue why this is occuring
                ctx.globalCompositeOperation = "source-over";
              }
              break;
          }
        }
      },
      [mask.image, mask.mask, maskingMode]
    );

    return (
      <div
        style={{
          width: width,
          height: height,
        }}
        className="block relative"
      >
        <Stage
          ref={stageRef}
          width={width}
          height={height}
          onWheel={debounce(handleOnWheel)}
          onDragMove={debounce(handleOnDragMove)}
          draggable
        >
          <Layer imageSmoothingEnabled={false} hitGraphEnabled={false}>
            {mask.image !== undefined && (
              <Shape
                sceneFunc={sceneFunc}
                width={mask.image.width}
                height={mask.image.height}
              />
            )}
          </Layer>
        </Stage>

        <div className="absolute bottom-4 bg-black bg-opacity-60 rounded-full px-6 py-2 text-white text-sm left-[50%] -translate-x-[50%]">
          {mask.label}
        </div>
      </div>
    );
  }
);

ImageVisualizer.displayName = "ImageVisualizer";

export default function MaskVisualizer2({
  masks,
  onPreviousImage,
  onNextImage,
  onClose,
}: {
  masks: MaskInfo[];
  onPreviousImage: () => void;
  onNextImage: () => void;
  onClose: () => void;
}) {
  const imageVisualizerRefs = useRef<(ImageVisualizerRef | null)[]>([]);
  const position = useRef<Vector2d>({ x: 0, y: 0 });
  const scale = useRef<Vector2d>({ x: 1, y: 1 });
  const [ref, { width, height }] = useElementSize();

  const numberOfImages = masks.length;
  const layerWidth = width / numberOfImages;
  const layerHeight = height;
  const referenceImage = masks[0].image;

  const setPosition = (newPosition: Vector2d) => {
    for (const shape of imageVisualizerRefs.current) {
      if (shape !== null) {
        shape.setPosition(newPosition);
      }
    }

    position.current = newPosition;
  };

  const getMinimumScale = (image: HTMLImageElement) => {
    const scaleX = layerWidth / image.width;
    const scaleY = layerHeight / image.height;

    return Math.min(scaleX, scaleY);
  };

  const setScale = (newScale: number) => {
    if (referenceImage !== undefined) {
      for (const shape of imageVisualizerRefs.current) {
        if (shape !== null) {
          shape.setScale(newScale);
        }
      }
    }

    scale.current = { x: newScale, y: newScale };
  };

  const onZoom = (position: Vector2d, newScale: number) => {
    if (referenceImage !== undefined) {
      setPosition(position);
      setScale(newScale);
    }
  };

  const resetZoom = (image: HTMLImageElement) => {
    const newScale = getMinimumScale(image);

    setScale(newScale);

    setPosition({
      x: (layerWidth - image.width * newScale) / 2,
      y: (layerHeight - image.height * newScale) / 2,
    });
  };

  // TODO: Use proper hook library for handling keyboard.
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowLeft":
          onPreviousImage();
          break;
        case "ArrowRight":
          onNextImage();
          break;
        case "Escape":
          onClose();
          break;
        case "d":
          changeMaskingMode();
          break;
        case "?":
        case "h":
          setIsShortcutsModalOpen(
            (isShortcutsModalOpen) => !isShortcutsModalOpen
          );
          break;
        case " ":
          if (referenceImage !== undefined) {
            resetZoom(referenceImage);
          }
          break;
        default:
          return;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [onPreviousImage, onNextImage, onClose]);

  // TODO: Use proper hook library for handling keyboard.
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case " ":
          if (referenceImage !== undefined) {
            resetZoom(referenceImage);
          }
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [resetZoom]);

  useEffect(() => {
    if (referenceImage !== undefined) {
      resetZoom(referenceImage);
    }
  }, [referenceImage]);

  const minimumScale = useMemo(() => {
    if (referenceImage !== undefined) {
      return getMinimumScale(referenceImage);
    } else {
      return 1;
    }
  }, [referenceImage]);

  useEffect(() => {
    imageVisualizerRefs.current.slice(0, masks.length);
  }, [masks.length]);

  const [openSimpleColorPicker, setOpenSimpleColorPicker] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(colors[0]);
  const [isShortcutsModalOpen, setIsShortcutsModalOpen] = useState(false);
  const [maskingMode, setMaskingMode] = useState<MaskingMode>(MaskingMode.MASK);

  const changeMaskingMode = () => {
    setMaskingMode((maskingMode) => {
      switch (maskingMode) {
        case MaskingMode.MASK:
          return MaskingMode.MASK_INVERSE;
        case MaskingMode.MASK_INVERSE:
          return MaskingMode.MASK_ONLY;
        case MaskingMode.MASK_ONLY:
          return MaskingMode.MASK;
      }
    });
  };

  return (
    <div
      ref={ref}
      className="flex-grow h-screen flex flex-row w-full"
      style={backgroundColorToStyle(backgroundColor)}
    >
      <ShortcutsModal
        shortcuts={{
          Left: "Previous image",
          Right: "Next image",
          Escape: "Back to overview",
          Space: "Reset zoom",
          d: "Change masking mode",
          "? or h": "Show/hide this modal",
        }}
        isOpen={isShortcutsModalOpen}
        onClose={() => {
          setIsShortcutsModalOpen(false);
        }}
      />

      {masks &&
        referenceImage !== undefined &&
        masks.map((mask, index) => (
          <ImageVisualizer
            key={index}
            ref={(ref) => (imageVisualizerRefs.current[index] = ref)}
            mask={mask}
            maskingMode={maskingMode}
            width={layerWidth}
            height={height}
            minimumScale={minimumScale}
            onZoom={onZoom}
            onDrag={setPosition}
          />
        ))}

      <button
        className="absolute top-4 right-4 p-2 bg-black bg-opacity-60 font-medium rounded-full text-white cursor-pointer"
        onClick={() => setIsShortcutsModalOpen(true)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
          />
        </svg>
      </button>

      <button
        className="absolute top-16 right-4 p-2 bg-black bg-opacity-60 font-medium rounded-full text-white cursor-pointer"
        onClick={() => changeMaskingMode()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
          />
        </svg>
      </button>

      <div className="absolute top-28 right-4 flex flex-row flex-grow-0 gap-4">
        {openSimpleColorPicker && (
          <div className="bg-black bg-opacity-60 p-4 rounded-xl">
            <SimpleColorPicker
              selectedColor={backgroundColor}
              onChange={setBackgroundColor}
            />
          </div>
        )}

        <button
          className="mb-auto p-2 bg-black bg-opacity-60 font-medium rounded-full text-white cursor-pointer"
          onClick={() =>
            setOpenSimpleColorPicker(
              (openSimpleColorPicker) => !openSimpleColorPicker
            )
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 11.25l1.5 1.5.75-.75V8.758l2.276-.61a3 3 0 10-3.675-3.675l-.61 2.277H12l-.75.75 1.5 1.5M15 11.25l-8.47 8.47c-.34.34-.8.53-1.28.53s-.94.19-1.28.53l-.97.97-.75-.75.97-.97c.34-.34.53-.8.53-1.28s.19-.94.53-1.28L12.75 9M15 11.25L12.75 9"
            />
          </svg>
        </button>
      </div>

      <button
        className="absolute top-4 left-4 p-2 bg-black bg-opacity-60 font-medium rounded-full text-white cursor-pointer"
        onClick={() => onClose()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
          />
        </svg>
      </button>
    </div>
  );
}
