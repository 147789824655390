import { UserProvider } from "hooks/useFirebaseUser";
import React from "react";
import { QueryClientProvider } from "react-query";
import { queryClient } from "services/apiServices";
import "./App.css";
import AppRouter from "./router";

function App() {
  return (
    <UserProvider>
      <QueryClientProvider client={queryClient}>
        <AppRouter />
      </QueryClientProvider>
    </UserProvider>
  );
}

export default App;
