import { Link } from "@tanstack/react-location";
import React from "react";


export default function NotFound() {
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <h1 className="mb-4 text-2xl font-semibold">Page not found 😥</h1>


    </div>
  );
}
