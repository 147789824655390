import { Dialog, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment, ReactNode } from "react";

interface Props {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  closeButton?: boolean;
  children: ReactNode;
  className?: string;
  closeLabel?: string;
  fullScreen?: boolean;
  style?: React.CSSProperties;
}

export default function Modal({
  isOpen = false,
  closeButton = false,
  setIsOpen,
  children,
  className = "",
  closeLabel = "Close",
  fullScreen = false,
  style = {},
}: Props): JSX.Element {
  function onClose() {
    setIsOpen(false);
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-20 overflow-y-auto"
        onClose={onClose}
      >
        <div
          className={classNames(
            "min-h-screen text-center",
            fullScreen ? "p-0" : "px-4"
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-50 backdrop-blur-xl backdrop-brightness-50" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                // removed overflow-hidden from this list to allow for elements outside of the modal
                // "inline-block w-full p-8 my-8 overflow-hidden text-left align-middle transition-all bg-white shadow-xl rounded-2xl space-y-6",
                "relative z-10 inline-block w-full space-y-2 bg-white text-left align-middle shadow-xl transition-all sm:space-y-6",
                fullScreen
                  ? "m-0 max-w-none rounded-none p-0"
                  : "my-8 max-w-lg rounded-2xl p-4 sm:p-8",
                className
              )}
              style={style}
            >
              {children}
              {closeButton && (
                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-2xl border border-transparent bg-ph-blue px-4 py-2 text-lg font-medium text-white shadow-md hover:bg-ph-blue/80 focus:outline-none focus-visible:ring-2 focus-visible:ring-ph-blue focus-visible:ring-offset-2"
                    onClick={onClose}
                  >
                    {closeLabel}
                  </button>
                </div>
              )}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
