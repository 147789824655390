import { ActionMeta, createFilter, OnChangeValue } from "react-select";
import CreatableSelect from "react-select/creatable";
import React from "react";
import { useDatasets } from "hooks/useDatasets";

interface SelectOption {
  readonly value: string;
  readonly label: string;
}
export function DatasetPicker({
  onDatasetChange,
  initialDataset,
}: {
  onDatasetChange: (dataset: string | undefined) => void;
  initialDataset: string | undefined;
}): JSX.Element {
  const {
    isLoading: datasetsLoading,
    error: datasetError,
    data: datasetPages,
  } = useDatasets();
  const datasetData = datasetPages?.pages.flatMap((page) => {
    return page.results.map((tag) => tag.id);
  });
  const handleChangeDataset = (
    newValue: OnChangeValue<SelectOption, false>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    console.log(`action: ${actionMeta.action}`);
    onDatasetChange(newValue?.value);
  };

  if (datasetError) {
    return <div>Error: {datasetError}</div>;
  }

  const datasetOptions = datasetData?.map((tag) => {
    return { value: tag, label: tag };
  });

  const initialDatasetOption = initialDataset
    ? ({ value: initialDataset, label: initialDataset } as SelectOption)
    : undefined;

  return (
    <div className="">
      <span className="block text-sm font-medium text-gray-700">Dataset</span>
      <CreatableSelect
        filterOption={createFilter({ ignoreAccents: false })} // Speed optimisation
        onChange={handleChangeDataset}
        options={datasetOptions}
        defaultValue={initialDatasetOption}
        isLoading={datasetsLoading}
      />
    </div>
  );
}
